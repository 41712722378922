const confirmContent = [
    {
        id: 0,
        title: "문제집 설정을 마치시겠습니까?",
        content: "확인을 누르면 현재까지의 내용이 저장됩니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 1,
        title: "시험 응시는 로그인 후 이용 가능합니다.",
        content: "확인을 누르면 로그인 화면으로 이동합니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 2,
        title: "시험을 중단하시겠습니까?",
        content: "현재 응시 내용은 저장되어 이어서 응시할 수 있습니다.",
        btn1Text: "저장하지 않고 나가기",
        btn2Text: "저장하기",
    },
    {
        id: 3,
        title: "오답노트를 삭제하시겠습니까?",
        content: "확인을 누르면 오답노트 목록에서 삭제됩니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 4,
        title: "클래스를 삭제하시겠습니까?",
        content: "확인을 누르면 클래스 목록에서 클래스가 삭제됩니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 5,
        title: "구성원을 삭제하시겠습니까?",
        content: "확인을 누르면 클래스에서 구성원이 삭제됩니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 6,
        title: "화상스터디 나가기",
        content: "확인버튼을 클릭하면 스터디에서 퇴장됩니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 7,
        title: "섹션 재정렬",
        content: "드래그하여 섹션을 재정렬할 수 있습니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 8,
        title: "시험 응시를 종료합니다.",
        content: "문제에 대해 한줄평을 남겨주세요!",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 9,
        title: "클래스 추가",
        content: "",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 10,
        title: "구성원 초대",
        content: "초대할 구성원의 닉네임을 입력해주세요.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 11,
        title: "화상스터디 개설",
        content: "",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 12,
        title: "문제집을 삭제하시겠습니까?",
        content: "문제집을 삭제하면 복구할 수 없습니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 13,
        title: "시험 응시를 종료합니다",
        content: "문제에 대해 한줄평을 남겨주세요!",
        btn1Text: "다음에 하기",
        btn2Text: "제출하기",
    },
    {
        id: 14,
        title: "섹션을 삭제하시겠습니까?",
        content: "섹션을 삭제하면 복구할 수 없습니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 15,
        title: "문제를 삭제하시겠습니까?",
        content: "문제를 삭제하면 복구할 수 없습니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 16,
        title: "임시저장이 완료되었습니다.",
        content: "임시저장된 문제집은 마이페이지의 출제내역에서 확인할 수 있습니다.",
        btn1Text: "계속 출제하기",
        btn2Text: "마이페이지로 이동",
    },
    {
        id: 17,
        title: "문제 형식을 변경하시겠습니까?",
        content: "문제 형식을 변경하면 현재 작성한 답안은 모두 삭제됩니다.",
        btn1Text: "",
        btn2Text: "",
    },
    {
        id: 18,
        title: "클래스를 나가겠습니까?",
        content: "탈퇴를 하기 위해선 방장 역할을 멤버에게 방장 역할을 넘겨주여야 합니다.",
        btn1Text: "취소",
        btn2Text: "확인",
    },
    {
        id: 19,
        title: "방장 권한을 부여하시겠습니까?",
        content: "방장 권한을 부여하시게되면 사용자의 역할은 멤버로 전환이 됩니다",
        btn1Text: "취소",
        btn2Text: "확인",
    },
]

export default confirmContent;