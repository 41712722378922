import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BookCard from "../../components/BookCard";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Button, MenuItem, TextField } from '@mui/material';
import SearchInput from "../../components/SearchInput";
import { useNavigate } from 'react-router-dom';

// 필터 조건
const conditions = [
  { value: 'popular', label: '인기순' },
  { value: 'recent', label: '최신순' },
  { value: 'old', label: '오래된순' },
  { value: 'title', label: '제목순' },
];

const ITEMS_PER_PAGE = 8; // 페이지당 아이템 수
const SPACING = 2; // 페이지네이션 간격


export default function PublishedBook() {
  const photopath = "https://kr.object.ncloudstorage.com/bitcamp701-129/final/book";

  const [bookList, setBookList] = useState([]); // 책 목록
  const [page, setPage] = useState(1); // 현재 페이지
  const [sort, setSort] = useState('popular'); // 정렬 기준
  const [user, setUser] = useState(null); // 사용자 ID
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 에러 상태
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태

  const navigate = useNavigate(); // useNavigate 호출

  // 페이지 변경 핸들러
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0); // 페이지가 바뀔 때 상단으로 이동
  };

  // 정렬 기준 변경 핸들러
  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  // 사용자 로그인 상태 및 ID 가져오기
  const fetchUserInfo = async () => {
    try {
      const res = await axios.get(`/book/username`); // 사용자 정보 요청
      setUser(res.data);
      setIsLoggedIn(true); // 로그인 상태
    } catch (error) {
      setIsLoggedIn(false);
    }
  };

  // 책 목록 가져오기
const getPublishedBooks = async () => {
  if (!user) return;

  try {
    const res = await axios.get(`/publishedbook/user-books?userId=${user.userId}`);
    
    // 책 목록을 createdate 기준으로 최신순으로 정렬
    const sortedBooks = res.data.sort((a, b) => new Date(b.bookCreatedate) - new Date(a.bookCreatedate));
    
    setBookList(sortedBooks);  // 정렬된 책 목록으로 상태 업데이트
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false); // 로딩 상태 해제
  }
};

  /*    setBookList(res.data);  // 책 목록 상태 업데이트
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false); // 로딩 상태 해제
    }
  };

*/
  // 컴포넌트가 마운트될 때 사용자 정보 및 책 목록 가져오기
  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (user) {
      getPublishedBooks();
    }
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // 현재 페이지에 표시할 항목 계산
  const itemOffset = (page - 1) * ITEMS_PER_PAGE;
  const currentBooks = bookList.slice(itemOffset, itemOffset + ITEMS_PER_PAGE);
  const pageCount = Math.ceil(bookList.length / ITEMS_PER_PAGE);

  // 버튼 클릭 핸들러
  const handleButtonClick = () => {
    navigate('/book/new'); // '/book/new' 페이지로 이동
  };

  const handleBookDelete = (bookId) => {
    setBookList((prevBookList) => prevBookList.filter((book) => book.bookId !== bookId));
  };

  return (
      <main className="flex-1 py-12 px-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">나의 출제이력</h1>
        </div>
        <div className="flex items-center space-x-4 justify-end mb-4">
          <Button variant="contained" size={"large"} onClick={handleButtonClick}>문제출제하기</Button>
        </div>

        {/* BookCard 출력 */}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          {currentBooks.length > 0 ? (
              currentBooks.map((book) => (
                  <BookCard
                      key={book.bookId}
                      bookId={book.bookId}
                      photo={`${photopath}/${book.bookImage}`}
                      cardType="B"
                      user={user}
                      nickname={book.user?.userNickname || "Unknown"}
                      className="flex-1"
                      createDate={book.bookCreatedate}
                      title={book.bookTitle}
                      bookUrl={`/book/detail/${book.bookId}`}
                      bookmarkCount={book.bookmarkCount}
                      bookQuestionCount={book.bookQuestionCount}
                      bookSectionCount={book.bookSectionCount}
                      status={book.bookStatus}
                      onDelete={handleBookDelete} // 삭제 핸들러 전달
                  />
              ))
          ) : (
              <div style={{fontSize : "30px"}}>출제한 문제집이 없습니다!</div>
          )}
        </div>
        {/* 페이지네이션 */}
        <Stack spacing={SPACING} justifyContent="center" direction="row" mt={4}>
          <Pagination
              count={pageCount} // 전체 페이지 수
              page={page} // 현재 페이지
              onChange={handleChange} // 페이지 변경 핸들러
              showFirstButton
              showLastButton
          />
        </Stack>
      </main>
  );
}
